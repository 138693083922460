
import React, { useState, useEffect, memo, useMemo, useRef } from 'react';
import ValuesStore from '../../store/values-store';
import { useSearchParams, Link, useLocation, useNavigate } from 'react-router-dom';
import utils from '../../dependencies/custom/react-utilities';
import CustomFunctions from '../../dependencies/custom/custom-functions';
import { Space, Card, Button, DatePicker, message, Tooltip } from 'antd';
// import { Card, Input, Avatar, Dropdown, Button, Affix, Space, Modal, Empty, Badge, Menu } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import qs from 'qs';
import useTable from '../../hooks/table';
import useEdit from '../../hooks/edit';
import useAdd from '../../hooks/add';
import useDelete from '../../hooks/delete';
import Settings from '../../dependencies/custom/settings';
import useModal from '../../hooks/modal';

const BalancePayments = (props) => {
    const valuesStore = ValuesStore();
    const edit = useEdit('tables_metadata', 'table_name');//make this hook be aware of where to get tbl metadata 
    const add = useAdd('tables_metadata', 'table_name');
    const reverse = useAdd('tables_metadata', 'table_name');
    const del = useDelete();
    const { filters, filterTypes } = utils.generateTableFilters();
    const navigate = useNavigate();
    const [dataToReverse, setDataToReverse] = useState();
    const modal = useModal();
    //and key value that points to the table names from zustand store.    
    const table = useTable(
        {
            pagination: {
                current: 1,
                pageSize: 10,
            },
            filters: { ...filters },
            filterTypes: { ...filterTypes }
        },
        `${Settings.backend}/get_balance_payment`,
        'post',
        'result',
        'totalCount',
        'id',
        { prop: 'LIKE', value: 'LIKE' },
        { table: 'txn', fields: ['*'] });

    const columns = ([
        {
            title: 'Type',
            dataIndex: 'txn_item',
        },
        {
            title: 'Flow',
            dataIndex: 'cash_flow',
            filters: [
                {
                    value: 'dr',
                    text: 'Debit'
                },
                {
                    value: 'cr',
                    text: 'Credit'
                }
            ],
        },
        {
            title: 'Amt',
            dataIndex: 'amt',
        },
        {
            title: 'Bal.',
            dataIndex: 'outstanding_bal',
        },
        {
            title: 'ReceiptNo.',
            dataIndex: 'from_where_id',
            ...table.getColumnSearchProps('from_where_id'),
        },

        {
            title: 'Remark',
            dataIndex: 'remark',
        },
        {
            title: 'Date',
            dataIndex: 'date_inserted',
            ...table.getColumnSearchProps('date_inserted'),
            render: (v, record) => {
                return utils.formatDate(v);
            }
        },
        {
            title: 'Actions',
            key: 'action',
            render: (_, record) => {
                return <Space size="middle">
                    <Tooltip title='View Details'><Button className='btn-successx border-0x' onClick={e => CustomFunctions.viewDetails(record,modal)}><i className='fas fa-eye text-primary' /></Button></Tooltip>
                    <Tooltip title='Reverse'><Button className='btn-successx border-0x' onClick={e => doReversal(record)}><i className='fas fa-undo text-success' /></Button></Tooltip>
                </Space>
            },
        },
    ]);


    async function balPaymentOk() {
        add.setLoading(true);
        let res = await utils.requestWithReauth('post', `${Settings.backend}/add_balance_payment`, null, add.record);
        if (res.status == 'Ok') {
            add.reset();
            message.success('Operation successful');
        } else {
            message.error(res.msg);
        }
        add.setLoading(false);
    }

    function addRecord(record, table = 'BALANCE_PAYMENT') {
        add.setTblName(table);
        add.setShowModal(true);
        add.setSaveCompleted(false);
    }


    useMemo(() => {
        table.setColumns(columns);
        console.log('looping')
        table.fetchData();
    }, [add.saveCompleted, edit.saveCompleted, del.saveCompleted, table.extraFetchParams]);

    function doReversal(record) {
        setDataToReverse(record);
        reverse.setTblName('reversals');
        reverse.setShowModal(true);
        reverse.setSaveCompleted(false);
    }

    async function reverseOk() {
        reverse.setLoading(true);
        let res = await utils.requestWithReauth('post', `${Settings.backend}/add_reversal`, null, { record: dataToReverse, recordInto: 'txn', finalRecordInto: 'txn', remark: reverse.record.remark, type: 'Balance Payment (Income)', amountFieldKey: 'amt' });
        if (res.status == 'Ok') {
            reverse.reset();
            message.success('Operation successful');
        } else {
            message.error(res.msg);
        }
        reverse.setLoading(false);
    }


    return (
        <>
            {/* {console.log('render render')} */}
            <div className='containerx' /*style={{ marginTop: '4rem' }}*/>
                <div className='row'>
                    <div className='col-md-12'>
                        <Card
                            bordered={false}
                            className="criclebox tablespace border-0 mb-24"
                            title="Balance Payment"
                            extra={
                                <Space>
                                    <DatePicker.RangePicker onChange={v => utils.filterByDate('date_inserted', v, table)} />
                                    <Button className='text-white amber accent-4 border-0' onClick={e => addRecord()}><i className='fas fa-plus me-2' /> New Balance Payment</Button>
                                </Space>
                            }
                        >
                            <div className="table-responsive">
                                {table.table}
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
            {add.addModal('Add Balance Payment', balPaymentOk)}
            {reverse.addModal('Balance Payment Reversal', reverseOk)}
            {modal.modalJSX(null, 600)}
        </>
    );
}

export default memo(BalancePayments);