import { Drawer } from 'antd';
import { useState, useMemo } from 'react';
import { Modal } from 'antd';
const useModal = () => {
    const [open, setOpen] = useState(false);

    const [content, setContent] = useState();
    const [title, setTitle] = useState();
    const [width, setWidth] = useState(378);
    const [height, setHeight] = useState(378);//number or string

    const [closable, setClosable] = useState();
    const [zIndex, setZIndex] = useState();
    const [size, setSize] = useState();
    const [style, setStyle] = useState();
    const [rootStyle, setRootStyle] = useState();
    const [rootClassName, setRootClassName] = useState();
    const [push, setPush] = useState();
    const [maskStyle, setMaskStyle] = useState();
    const [maskClosable, setMaskClosable] = useState(true);//boolean default:true
    const [mask, setMask] = useState(true);//boolean default: true
    const [keyboard, setKeyboard] = useState(true);//boolean default:true    
    const [headerStyle, setHeaderStyle] = useState();//css properties

    const onClose = () => {
        setOpen(false);
    };

    function modalJSX(handleOk,localWidth=500) {
        return <Modal title={title} open={open} onOk={handleOk} footer={null} width={localWidth || width}  onCancel={onClose}>
            {content}
        </Modal>
    }

    useMemo(() => {
        // console.log(content, open);
    }, [open]);


    return {
        open, setOpen, content, setContent, title, setTitle, modalJSX, width, setWidth, height, setHeight
    };
};
export default useModal;