
import React, { useState, useEffect, memo, useMemo, useRef } from 'react';
import ValuesStore from '../../store/values-store';
import { useSearchParams, Link, useLocation, useNavigate } from 'react-router-dom';
import utils from '../../dependencies/custom/react-utilities';
import { Space, Card, Button, DatePicker, message, Tag } from 'antd';

import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import qs from 'qs';
import useTable from '../../hooks/table';
import useEdit from '../../hooks/edit';
import useAdd from '../../hooks/add';
import useDelete from '../../hooks/delete';
import Settings from '../../dependencies/custom/settings';


const Inventory = (props) => {
    const valuesStore = ValuesStore();
    const edit = useEdit('tables_metadata', 'table_name');//make this hook be aware of where to get tbl metadata 
    const add = useAdd('tables_metadata', 'table_name');
    // const reverse = useAdd('tables_metadata', 'table_name');
    const del = useDelete();
    const { filters, filterTypes } = utils.generateTableFilters();
    const [dataToReverse, setDataToReverse] = useState();
    const navigate = useNavigate();
    //and key value that points to the table names from zustand store.    
    const table = useTable(
        {
            pagination: {
                current: 1,
                pageSize: 10,
            },
            filters: { ...filters },
            filterTypes: { ...filterTypes }
        },
        `${Settings.backend}/get_inventory`,
        'post',
        'result',
        'totalCount',
        'id',
        {},
        { table: 'inventory', fields: ['*'] });

    const columns = ([
        {
            title: 'Reversal',
            dataIndex: 'is_reversal',
            // ...table.getColumnSearchProps('pd_item'),
            render: (v, record) => {
                return <Tag color={v ? 'blue-inverse' : 'green-inverse'}>{v ? 'Yes' : 'No'}</Tag>
            }

        },
        {
            title: 'ID',
            dataIndex: 'custom_id',
            ...table.getColumnSearchProps('custom_id'),
        },
        {
            title: 'Type',
            dataIndex: 'type',
            filterSearch:true
        },
        {
            title: 'Flow',
            dataIndex: 'flow',
            filters: [
                {
                    value: 'dr',
                    text: 'Debit'
                },
                {
                    value: 'cr',
                    text: 'Credit'
                }
            ],
        },
        {
            title: 'Product',
            dataIndex: 'product',
            filterSearch:true,
            render: (v, record) => {
                return record.product_item;
            }            
        },
        {
            title: 'Qty',
            dataIndex: 'qty',
        },
        {
            title: 'Overall Qty.',
            dataIndex: 'cum_qty',        
        },
        {
            title: 'Type. Qty.',
            dataIndex: 'inv_item_cum',
        },
        {
            title: 'Product Qty',
            dataIndex: 'product_cum',           
        },
        {
            title: 'Date',
            dataIndex: 'date_inserted',
            render: (v, record) => {
                return utils.formatDate(v);
            }
        },
        {
            title: 'Actions',
            key: 'action',
            render: (_, record) => {
                return <Space size="middle">
                    <Button className='btn-successx border-0x' onClick={e => editRecord(record, 'inventory')}><i className='fas fa-undo text-success' /></Button>                   
                </Space>
            },
        },
    ]);

    async function editOnOk() {
        edit.setLoading(true);
        let res = await utils.requestWithReauth('post', `${Settings.backend}/edit_inventory`, null, { record: edit.record, type: 'production' });
        if (res.status == 'Ok') {
            edit.reset();
            message.success('Operation successful');
        } else {
            message.error(res.msg);
        }
        edit.setLoading(false);
    }

    function editRecord(record, tableName) {
        const storeKey = 'editableRecord';
        valuesStore.setValue(storeKey, record);
        edit.setTblName(tableName);
        edit.setData(record);
        edit.setRecordKey(storeKey);
        edit.setShowModal(true);
        edit.setSaveCompleted(false);
    }

    function addRecord(tableName = 'inventory') {
        add.setTblName(tableName);
        add.setShowModal(true);
        add.setSaveCompleted(false);
    }


    async function addOnOk() {
        add.setLoading(true);
        let res = await utils.requestWithReauth('post', `${Settings.backend}/add_inventory`, null, add.record);
        if (res.status == 'Ok') {
            add.reset();
            message.success('Operation successful');
        } else {
            message.error(res.msg);
        }
        add.setLoading(false);
    }

    const colFilters = [       
        {
            filter: 'product',
            sql: 'SELECT custom_id,product_item FROM products WHERE for_inventory = 1',
            key: 'custom_id',
            value: 'product_item'
        },
        {
            filter: 'type',
            sql: "SELECT alias,item FROM flow_types WHERE group_name = 'inventory'",
            key: 'alias',
            value: 'item'
        }
    ];
    useMemo(() => {
        table.setColumns(columns);
        table.setColFilters(colFilters, columns, `${Settings.backend}/get_col_filters`);
        console.log('looping')
        table.fetchData();
    }, [add.saveCompleted, edit.saveCompleted, del.saveCompleted,table.extraFetchParams]);


    return (
        <>
            {/* {console.log('render render')} */}
            <div className='containerx' /*style={{ marginTop: '4rem' }}*/>
                <div className='row'>
                    <div className='col-md-12'>
                        <Card
                            bordered={false}
                            className="criclebox tablespace border-0 mb-24"
                            title="Inventory"
                            extra={
                                <Space>
                                    <DatePicker.RangePicker onChange={v => utils.filterByDate('date_inserted', v, table)} />
                                    <Button className='text-white amber accent-4 border-0' onClick={e => addRecord()}><i className='fas fa-plus me-2' /> New Inventory</Button>
                                </Space>
                            }
                        >
                            <div className="table-responsive">
                                {table.table}
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
            {edit.editModal('Edit Inventory', editOnOk)}
            {add.addModal('Add Inventory', addOnOk)}
            {/* {reverse.addModal('Reverse Production', reverseOk)} */}
        </>
    );
}

export default memo(Inventory);