
import React, { useState, useEffect, memo, useMemo, useRef } from 'react';
import ValuesStore from '../../store/values-store';
import { useSearchParams, Link, useLocation, useNavigate } from 'react-router-dom';
import utils from '../../dependencies/custom/react-utilities';
import { Space, Card, Button, message, Tag, DatePicker } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import qs from 'qs';
import useTable from '../../hooks/table';
import useDelete from '../../hooks/delete';
import Settings from '../../dependencies/custom/settings';
import CustomFunctions from '../../dependencies/custom/custom-functions';

const Reversals = (props) => {
    const valuesStore = ValuesStore();
    const del = useDelete();
    const { filters, filterTypes } = utils.generateTableFilters();
    const navigate = useNavigate();
    const [forceRender, setForceRender] = useState(false);

    //and key value that points to the table names from zustand store.    
    const table = useTable(
        {
            pagination: {
                current: 1,
                pageSize: 10,
                hideOnSinglePage: true
            },
            filters: { ...filters },
            filterTypes: { ...filterTypes }
        },
        `${Settings.backend}/get_data`,
        'post',
        'result',
        'totalCount',
        'id',
        { prop: 'LIKE', value: 'LIKE' },
        { table: 'reversals', fields: ['*'] });

    const columns = ([
        {
            title: 'TxnID',
            dataIndex: 'txn_id',
            ...table.getColumnSearchProps('txn_id'),
        },
        {
            title: 'Type',
            dataIndex: 'type',
            ...table.getColumnSearchProps('type'),
        },
        {
            title: 'Amount',
            dataIndex: 'amt',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            filters: [
                {
                    value: 'entry',
                    text: 'Entry'
                },
                {
                    value: 'approve',
                    text: 'Approved'
                }
            ],
            render: (v, record) => {
                return <Tag color={v == 'entry' ? 'red-inverse' : 'green-inverse'}>{v == 'entry' ? 'Entry' : 'Approved'}</Tag>
            }
        },
        {
            title: 'Declined',
            dataIndex: 'declined',
            filters: [
                {
                    value: 1,
                    text: 'Yes'
                },
                {
                    value: 0,
                    text: 'No'
                }
            ],
            render: (v, record) => {
                return <Tag color={v ? 'red-inverse' : 'green-inverse'}>{v ? 'Yes' : 'No'}</Tag>
            }
        },
        {
            title: 'Remark',
            dataIndex: 'remark',
        },
        {
            title: 'Date',
            dataIndex: 'date_inserted',
            ...table.getColumnSearchProps('date_inserted'),
            render: (v, record) => {
                return utils.formatDate(v);
            }
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => {
                return <Space size="middle">
                    <Button className='btn-success border-0' onClick={e => changeTxnStatus(record, 'approve', 'single')}><i className='fas fa-check-double text-successx' /></Button>
                    <Button className='btn-warning border-0' onClick={e => changeTxnStatus(record, 'decline', 'single')}><i className='fas fa-times text-successx' /></Button>
                    {del.confirm(
                        `${Settings.backend}/delete_reversal`,
                        record,
                        'Are you sure to delete this item',
                        { tableName: 'reversals', where: 'id', whereType: 'closed' },
                        <Button className='btn-danger border-0'><i className='fas fa-trash text-dangerx' /></Button>
                    )}
                </Space>
            },
        },
    ]);


    
    async function changeTxnStatus(record, status, type) {
        switch (status) {
            // case "review": {
            //     if (type === 'multiple') {
            //         if (table.selectedRows.length) {
            //             CustomFunctions.changeTxnStatus(table.selectedRows, 'review', 'id');
            //         } else {
            //             message.error('No selection was done');
            //         }
            //     } else {
            //         CustomFunctions.changeTxnStatus([record], 'review', 'id');
            //     }

            //     break;
            // }       
            case "approve": {
                let res = null;
                if (type === 'multiple') {
                    if (table.selectedRows.length) {
                        res = await CustomFunctions.changeTxnStatus(table.selectedRows, 'approve', 'txn_id');
                    } else {
                        message.error('No selection was done');
                    }
                } else {
                    res = await CustomFunctions.changeTxnStatus([record], 'approve', 'txn_id');
                }
                if (res) {
                    if (res.status == 'Ok') {
                        setForceRender(v=>!v);
                    }
                }
                break;
            }
            case "decline": {
                let res = null;
                if (type === 'multiple') {
                    if (table.selectedRows.length) {
                        res = await CustomFunctions.changeTxnStatus(table.selectedRows, 'decline', 'txn_id');
                    } else {
                        message.error('No selection was done');
                    }
                } else {
                    res = await CustomFunctions.changeTxnStatus([record], 'decline', 'txn_id');
                }
                if (res) {
                    if (res.status == 'Ok') {
                        setForceRender(v=>!v);
                    }
                }
                break;
            }
        }
    }

    useMemo(() => {
        table.setColumns(columns);
        console.log('looping')
        table.fetchData();
        table.setAllowSelection(true);
    }, [del.saveCompleted, table.extraFetchParams,forceRender]);


    return (
        <>
            {/* {console.log('render render')} */}
            <div className='containerx' /*style={{ marginTop: '4rem' }}*/>
                <div className='col-md-12'>
                    <Card
                        bordered={false}
                        className="criclebox tablespace border-0 mb-24"
                        title="Reversals"
                        extra={
                            <Space>
                                <DatePicker.RangePicker onChange={v => utils.filterByDate('date_inserted', v, table)} />
                            </Space>
                        }
                    >
                        <div className="table-responsive">
                            {table.table}
                        </div>
                    </Card>
                </div>
            </div>
        </>
    );
}

export default memo(Reversals);