import utils from "./react-utilities";
import Settings from "./settings";
import { message } from "antd";
const CustomFunctions = {
    // async viewTxn(record, drawer, valuesStore, Tag, Divider, Space, isMainTxn = true) {
    //     const res = await utils.requestWithReauth('post', `${Settings.backend}/get_student`, null, { indexNumber: record.index_no });
    //     const student = res?.result[0];
    //     drawer.setOpen(true);
    //     drawer.setPlacement('right');
    //     drawer.setClosable(false);
    //     const payItemRealName = valuesStore.getArrayObjectsValue('pay_items', 'alias', record?.pay_item);
    //     const content = <div className='d-flex flex-column'>
    //         <div>
    //             <Divider orientation='center' className='fw-bold text-danger'>Transaction Details</Divider>
    //             <Space direction='vertical fw-bold'>
    //                 <label className='h4'>TxnID: {record?.bank_txn_id || 'N/A'}</label>
    //                 <label className='h4'>Amount: GHC {record?.amt_paid || 'N/A'}</label>
    //                 <label >Cheque No.: {record?.cheque_no || 'N/A'}</label>
    //                 <label>
    //                     Flow: <Tag
    //                         color={record?.debit_credit == 'dr' ? '#2db7f5' : 'geekblue-inverse'}
    //                     >{record?.debit_credit.toUpperCase() || 'N/A'}</Tag>
    //                 </label>

    //                 <label>Pay Item: {payItemRealName?.item || 'N/A'}</label>

    //                 <label>
    //                     Type: <Tag
    //                         color={record?.is_reversal ? 'grey' : '#00695c'}
    //                     >{record?.is_reversal ? 'Reversal' : 'Not Reversal'}</Tag>
    //                 </label>
    //                 {
    //                     !isMainTxn &&
    //                     <label> Action: {
    //                         record?.declined ?
    //                             <Tag color='volcano' icon={<i className='fas fa-trash' />}> {record?.status?.toUpperCase() || 'N/A'}</Tag>
    //                             : <Tag color='green' icon={<i className='fas fa-check' />}> {record?.status?.toUpperCase() || 'N/A'}</Tag>
    //                     }
    //                     </label>
    //                 }
    //                 <label>Description: {record?.description || 'N/A'}</label>
    //                 <label>Date: {record?.date_inserted || 'N/A'}</label>
    //             </Space>
    //         </div>

    //         <div>
    //             <Divider orientation='center' className='fw-bold text-danger'>Bio Details</Divider>
    //             <Space direction='vertical fw-bold'>
    //                 <label>Student First Name: {record?.fname ?? student?.fname ?? 'N/A'}</label>
    //                 <label>Student Surname: {record?.lname ?? student?.lname ?? 'N/A'}</label>
    //                 <label>Student Middle Name: {record?.mname ?? student?.mname ?? 'N/A'}</label>
    //                 <label>Student Sex: {record?.sex ?? student?.sex ?? 'N/A'}</label>
    //                 <label>Student Level: {record?.current_level ?? student?.current_level ?? 'N/A'}</label>
    //                 <label>Student Session: {record?.session ?? student?.session ?? 'N/A'}</label>
    //                 <label>Student Index No.: {record?.index_no ?? student?.index_no ?? 'N/A'}</label>
    //                 {isMainTxn && <label>Student Status: {record?.status ?? student?.status ?? 'N/A'} </label>}
    //                 <label>Payer Name: {record?.payer_name ?? student?.payer_name ?? 'N/A'}</label>
    //                 <label>Payer Contact: {record?.payer_contact ?? student?.payer_contact ?? 'N/A'}</label>

    //             </Space>
    //         </div>

    //         <div>
    //             <Divider orientation='center' className='fw-bold text-danger'>Bank Details</Divider>
    //             <Space direction='vertical fw-bold'>
    //                 <label>Bank: {record?.bank_code || 'N/A'}</label>
    //                 <label>Account: {record?.bank_acct || 'N/A'}</label>
    //                 <label>Payment Mode: {record?.payment_type || 'N/A'}</label>
    //                 <label>Teller: {record?.teller || 'N/A'}</label>
    //             </Space>
    //         </div>
    //     </div>
    //     drawer.setContent(content);
    // },


    async changeTxnStatus(details, type, idField) {
        let url = '';
        switch (type) {
            case "review": {
                url = `${Settings.backend}/review_txn`;
                break;
            }
            case "approve": {
                url = `${Settings.backend}/approve_txn`;
                break;
            }
            case "decline": {
                url = `${Settings.backend}/decline_txn`;
                break;
            }
        }
        const data = details.map(v => (v[idField]));
        let res = await utils.requestWithReauth('post', url, null, { data });
        if (res.status === 'Ok') {
            message.success('Operation successful');
        } else {
            message.error(res.msg);
        }
        return res;
    },

    viewDetails(record, modal) {
        const details = [
            ['ID', record.custom_id],
            ['Transaction Type', record.txn_item],
            ['Amount', record.amt],
            ['Flow', record.cash_flow],
            ['Recorder', record.recorded_by],
            ['Description', record.description],
            ['Payment Mode', record.payment_mode],
            ['Receipt No./ID', record.from_where_id],
            ['Date', record.date_inserted],
            ['Remark', record.remark]
        ];
        
        const details2 = utils.getTable(
            [
                <label className='fw-bold'>Item</label>,
                <label className='fw-bold'>Value</label>,
            ],
            details, 'w-100 table table-sm table-striped', '', '', '');
        
        modal.setTitle(`Details`);
        modal.setContent(
            <div className='row'>
                <div className='col-md-12'>
                    {details2}
                </div>              
            </div>

        );
        modal.setOpen(true);
    }


}

export default CustomFunctions;