
import React, { useState, useEffect, memo, useMemo, useRef } from 'react';
import ValuesStore from '../../store/values-store';
import { useSearchParams, Link, useLocation, useNavigate } from 'react-router-dom';
import utils from '../../dependencies/custom/react-utilities';
import { Space, Card, Button, DatePicker, message, Tag } from 'antd';
// import { Card, Input, Avatar, Dropdown, Button, Affix, Space, Modal, Empty, Badge, Menu } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import qs from 'qs';
import useTable from '../../hooks/table';
import useEdit from '../../hooks/edit';
import useAdd from '../../hooks/add';
import useDelete from '../../hooks/delete';
import Settings from '../../dependencies/custom/settings';


const Cost = (props) => {
    const valuesStore = ValuesStore();    
    const add = useAdd('tables_metadata', 'table_name');
    const reverse = useAdd('tables_metadata', 'table_name');
    const del = useDelete();
    const { filters, filterTypes } = utils.generateTableFilters();
    const [dataToReverse, setDataToReverse] = useState();
    const navigate = useNavigate();
    //and key value that points to the table names from zustand store.    
    const table = useTable(
        {
            pagination: {
                current: 1,
                pageSize: 10,
            },
            filters: { ...filters },
            filterTypes: { ...filterTypes }
        },
        `${Settings.backend}/get_data`,
        'post',
        'result',
        'totalCount',
        'id',
        { prop: 'LIKE', value: 'LIKE' },
        { table: 'cost', fields: ['*'] });

    const columns = ([
        {
            title: 'Reversal',
            dataIndex: 'has_reversed',
            filters: [
                {
                    value: 1,
                    text: 'Yes'
                },
                {
                    value: 0,
                    text: 'No'
                }
            ],
            render: (v, record) => {
                return <Tag color={v ? 'blue-inverse' : 'green-inverse'}>{v ? 'Yes' : 'No'}</Tag>
            }

        },
        {
            title: 'Type',
            dataIndex: 'type',            
        },
        {
            title: 'Description',
            dataIndex: 'description',            
        },
        {
            title: 'Qty',
            dataIndex: 'qty',            
        },
        {
            title: 'Unit Price',
            dataIndex: 'unit_price',            
        },
        {
            title: 'Total Amount',
            dataIndex: 'total_amount',            
        },
        {
            title: 'Date',
            dataIndex: 'date_inserted',
            ...table.getColumnSearchProps('date_inserted'),
            render: (v, record) => {
                return utils.formatDate(v);
            }
        },
        {
            title: 'Actions',
            key: 'action',
            render: (_, record) => {
                return <Space size="middle">                    
                    <Button className='btn-successx border-0x' onClick={e => doReversal(record, 'cost')}><i className='fas fa-undo text-success' /></Button>                    
                </Space>
            },
        },
    ]);



    function doReversal(record) {
        setDataToReverse(record);
        reverse.setTblName('reversals');
        reverse.setShowModal(true);
        reverse.setSaveCompleted(false);
    }

    async function reverseOk() {
        reverse.setLoading(true);
        let res = await utils.requestWithReauth('post', `${Settings.backend}/add_reversal`, null, { record: dataToReverse, recordInto: 'cost', finalRecordInto: 'txn', remark: reverse.record.remark, type: 'Cost (Expense)', amountFieldKey: 'total_amount'  });
        if (res.status == 'Ok') {
            reverse.reset();
            message.success('Operation successful');
        } else {
            message.error(res.msg);
        }
        reverse.setLoading(false);
    }


    function addRecord(tableName = 'cost') {
        add.setTblName(tableName);
        add.setShowModal(true);
        add.setSaveCompleted(false);
    }

  

    async function addOnOk() {
        add.setLoading(true);
        let res = await utils.requestWithReauth('post', `${Settings.backend}/add_cost`, null, add.record);
        if (res.status == 'Ok') {
            add.reset();
            message.success('Operation successful');
        } else {
            message.error(res.msg);
        }
        add.setLoading(false);
    }

    useMemo(() => {
        table.setColumns(columns);
        console.log('looping')
        table.fetchData();
    }, [add.saveCompleted, del.saveCompleted,table.extraFetchParams,reverse.saveCompleted]);


    return (
        <>
            {/* {console.log('render render')} */}
            <div className='containerx' /*style={{ marginTop: '4rem' }}*/>
                <div className='row'>                    
                    <div className='col-md-12'>
                    <Card
                        bordered={false}
                        className="criclebox tablespace border-0 mb-24"
                        title="Cost"
                        extra={
                            <Space>
                                <DatePicker.RangePicker onChange={v => utils.filterByDate('date_inserted', v, table)} />
                                <Button className='text-white amber accent-4 border-0' onClick={e => addRecord()}><i className='fas fa-plus me-2' /> New Cost</Button>
                            </Space>
                        }
                    >
                        <div className="table-responsive">
                            {table.table}
                        </div>
                    </Card>
                </div>
                </div>
            </div>
            
            {add.addModal('Add Cost', addOnOk)}
            {reverse.addModal('Cost Reversal', reverseOk)}
        </>
    );
}

export default memo(Cost);