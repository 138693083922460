
import React, { useState, useEffect, memo, useMemo, useRef } from 'react';
import ValuesStore from '../../store/values-store';
import { useSearchParams, Link, useLocation, useNavigate } from 'react-router-dom';
import utils from '../../dependencies/custom/react-utilities';
import { Space, Card, Button, DatePicker, message, Tag } from 'antd';
// import { Card, Input, Avatar, Dropdown, Button, Affix, Space, Modal, Empty, Badge, Menu } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import qs from 'qs';
import useTable from '../../hooks/table';
import useEdit from '../../hooks/edit';
import useAdd from '../../hooks/add';
import useDelete from '../../hooks/delete';
import Settings from '../../dependencies/custom/settings';


const Suppliers = (props) => {
    const valuesStore = ValuesStore();
    const edit = useEdit('tables_metadata', 'table_name');//make this hook be aware of where to get tbl metadata 
    const add = useAdd('tables_metadata', 'table_name');
    const deliveryAdd = useAdd('tables_metadata', 'table_name');
    const balPayment = useAdd('tables_metadata', 'table_name');
    const del = useDelete();
    const { filters, filterTypes } = utils.generateTableFilters();
    const navigate = useNavigate();
    //and key value that points to the table names from zustand store.    
    const table = useTable(
        {
            pagination: {
                current: 1,
                pageSize: 10,
            },
            filters: { ...filters },
            filterTypes: { ...filterTypes }
        },
        `${Settings.backend}/get_data`,
        'post',
        'result',
        'totalCount',
        'id',
        { prop: 'LIKE', value: 'LIKE' },
        { table: 'suppliers', fields: ['*'] });

    const columns = ([
        {
            title: 'Name',
            dataIndex: 'name',
            ...table.getColumnSearchProps('name'),
        },
        {
            title: 'Contact',
            dataIndex: 'contact',
            ...table.getColumnSearchProps('contact'),
        },
        {
            title: 'Truck Number',
            dataIndex: 'truck_no',
            ...table.getColumnSearchProps('truck_no'),
        },
        {
            title: 'Date',
            dataIndex: 'date_inserted',
            ...table.getColumnSearchProps('date_inserted'),
            render: (v, record) => {
                return utils.formatDate(v);
            }
        },
        {
            title: 'Actions',
            key: 'action',
            render: (_, record) => {
                return <Space size="middle">                    
                    <Button className='btn-successx border-0x' onClick={e => editRecord(record, 'suppliers')}><i className='fas fa-edit text-success' /></Button>
                    {del.confirm(
                        `${Settings.backend}/delete`,
                        record,
                        'Are you sure to delete this item',
                        { tableName: 'suppliers', where: 'id', whereType: 'closed'},
                        <Button className='btn-dangerx border-0x'><i className='fas fa-trash text-danger' /></Button>
                    )}                    
                </Space>
            },
        },
    ]);



    function editRecord(record, tableName) {
        const storeKey = 'editableRecord';
        valuesStore.setValue(storeKey, record);
        edit.setTblName(tableName);
        edit.setData(record);
        edit.setRecordKey(storeKey);
        edit.setShowModal(true);
        edit.setSaveCompleted(false);
    }

    function addRecord(tableName = 'suppliers') {
        add.setTblName(tableName);
        add.setShowModal(true);
        add.setSaveCompleted(false);
    }

    async function editOnOk() {
        let res = await edit.save(undefined, `${Settings.backend}/edit`, 'suppliers', { tbl: 'suppliers', where: 'id', whereType: 'closed' })        
    }


    async function addOnOk() {
        let res = await add.save(`${Settings.backend}/add`, { tbl: 'suppliers' })      
    }

    useMemo(() => {
        table.setColumns(columns);
        console.log('looping')
        table.fetchData();
    }, [add.saveCompleted, edit.saveCompleted, del.saveCompleted]);


    return (
        <>
            {/* {console.log('render render')} */}
            <div className='containerx' /*style={{ marginTop: '4rem' }}*/>
                <div className='row'>                    
                    <div className='col-md-12'>
                        <Card
                            bordered={false}
                            className="criclebox tablespace border-0 mb-24"
                            title="Suppliers"
                            extra={
                                <Space>
                                    <DatePicker.RangePicker onChange={v => utils.filterByDate('date_inserted', v, table)} />
                                    <Button className='text-white amber accent-4 border-0' onClick={e => addRecord()}><i className='fas fa-plus me-2' /> New Supplier</Button>
                                </Space>
                            }
                        >
                            <div className="table-responsive">
                                {table.table}
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
            {edit.editModal('Edit Supplier', editOnOk)}
            {add.addModal('Add Supplier', addOnOk)}
        </>
    );
}

export default memo(Suppliers);