
import React, { useState, useEffect, memo, useMemo, useRef } from 'react';
import ValuesStore from '../../store/values-store';
import { useSearchParams, Link, useLocation, useNavigate } from 'react-router-dom';
import utils from '../../dependencies/custom/react-utilities';
import { Space, Button, DatePicker, message, Tag, Card } from 'antd';
// import { Card, Input, Avatar, Dropdown, Button, Affix, Space, Modal, Empty, Badge, Menu } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import qs from 'qs';
import useTable from '../../hooks/table';
import useEdit from '../../hooks/edit';
import useAdd from '../../hooks/add';
import useDelete from '../../hooks/delete';
import Settings from '../../dependencies/custom/settings';


const Production = (props) => {
    const valuesStore = ValuesStore();
    const edit = useEdit('tables_metadata', 'table_name');//make this hook be aware of where to get tbl metadata 
    const add = useAdd('tables_metadata', 'table_name');
    // const reverse = useAdd('tables_metadata', 'table_name');
    const del = useDelete();
    const { filters, filterTypes } = utils.generateTableFilters();
    const [dataToReverse, setDataToReverse] = useState();
    const navigate = useNavigate();
    //and key value that points to the table names from zustand store.    
    const table = useTable(
        {
            pagination: {
                current: 1,
                pageSize: 10,
            },
            filters: { ...filters },
            filterTypes: { ...filterTypes }
        },
        `${Settings.backend}/get_production`,
        'post',
        'result',
        'totalCount',
        'id',
        {},
        { table: 'production2delivery_txn', fields: ['*'] });

    const columns = ([
        {
            title: 'Reversal',
            dataIndex: 'is_reversal',
            filters: [
                {
                    value: 1,
                    text: 'Yes'
                },
                {
                    value: 0,
                    text: 'No'
                }
            ],
            render: (v, record) => {
                return <Tag color={v ? 'blue-inverse' : 'green-inverse'}>{v ? 'Yes' : 'No'}</Tag>
            }

        },
        {
            title: 'ID',
            dataIndex: 'custom_id',
            ...table.getColumnSearchProps('custom_id'),
        },
        {
            title: 'Txn. Item',
            dataIndex: 'pd_item',            
        },
        {
            title: 'Machine',
            dataIndex: 'machine',            
        },
        {
            title: 'Product',
            dataIndex: 'product_item',
            filterSearch: true            
        },
        {
            title: 'Pallette',
            dataIndex: 'pallette',
        },
        {
            title: 'Qty',
            dataIndex: 'qty',
            render: (value, record) => {
                return Math.round(value);
             },           
        },
        {
            title: 'Cum. Qty.',
            dataIndex: 'cum_qty',
            render: (value, record) => {
                return Math.round(value);
             },
        },
        {
            title: 'Machine Qty',
            dataIndex: 'machine_cum', 
            render: (value, record) => {
                return Math.round(value);
             },           
        },
        {
            title: 'Blk Site',
            dataIndex: 'product_cum',
            render: (value, record) => {
                return Math.round(value);
             },            
        },
        {
            title: 'Date',
            dataIndex: 'date_inserted',
            ...table.getColumnSearchProps('date_inserted'),
            render: (v, record) => {
                return utils.formatDate(v);
            }
        },
        {
            title: 'Actions',
            key: 'action',
            render: (_, record) => {
                return <Space size="middle">
                    <Button className='btn-successx border-0x' onClick={e => editRecord(record, 'PRODUCTION')}><i className='fas fa-undo text-success' /></Button>
                    {/* <Button className='btn-successx border-0x' onClick={e => doReversal(record)}><i className='fas fa-undo text-secondary' /></Button> */}
                    {/* {del.confirm(
                        `${Settings.backend}/delete`,
                        record,
                        'Are you sure to delete this item',
                        { tableName: 'PRODUCTION', where: 'id', whereType: 'closed'},
                        <Button className='btn-dangerx border-0x'><i className='fas fa-trash text-danger' /></Button>
                    )}                     */}
                </Space>
            },
        },
    ]);


    function editRecord(record, tableName) {
        const storeKey = 'editableRecord';
        valuesStore.setValue(storeKey, record);
        edit.setTblName(tableName);
        edit.setData(record);
        edit.setRecordKey(storeKey);
        edit.setShowModal(true);
        edit.setSaveCompleted(false);
    }

    function addRecord(tableName = 'PRODUCTION') {
        add.setTblName(tableName);
        add.setShowModal(true);
        add.setSaveCompleted(false);
    }

    async function editOnOk() {
        edit.setLoading(true);
        let res = await utils.requestWithReauth('post', `${Settings.backend}/edit_production2delivery_txn`, null, { record: edit.record, type: 'production' });
        if (res.status == 'Ok') {
            edit.reset();
            message.success('Operation successful');
        } else {
            message.error(res.msg);
        }
        edit.setLoading(false);
    }

    async function addOnOk() {
        add.setLoading(true);
        let res = await utils.requestWithReauth('post', `${Settings.backend}/add_production`, null, add.record);
        if (res.status == 'Ok') {
            add.reset();
            message.success('Operation successful');            
        } else {
            message.error(res.msg);
        }
        add.setLoading(false);
    }

    const colFilters = [
        {
            filter: 'machine',
            sql: "SELECT machine FROM machines ",
            key: 'machine',
            value: 'machine'
        },
        {
            filter: 'product_item',
            sql: 'SELECT custom_id,product_item FROM products WHERE for_inventory = 0',
            key: 'custom_id',
            value: 'product_item'
        }
    ];


    useMemo(() => {
        table.setColumns(columns);
        table.setColFilters(colFilters, columns, `${Settings.backend}/get_col_filters`);
        // table.setCssClasses('ant-border-space');
        console.log('looping');
        table.fetchData();
    }, [add.saveCompleted, edit.saveCompleted, del.saveCompleted, table.extraFetchParams]);


    return (
        <>
            {/* {console.log('render render')} */}
            <div className='containerx' /*style={{ marginTop: '4rem' }}*/>
                <div className='row'>
                    <div className='col-md-12'>
                        <Card
                            bordered={false}
                            className="criclebox tablespace border-0 mb-24"
                            title="Production"
                            extra={
                                <Space>
                                    <DatePicker.RangePicker onChange={v => utils.filterByDate('date_inserted', v, table)} />
                                    <Button className='text-white amber accent-4 border-0' onClick={e => addRecord()}><i className='fas fa-plus me-2' /> Add Production</Button>
                                </Space>
                            }
                        >
                            <div className="table-responsive">
                                {table.table}
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
            {edit.editModal('Edit Production', editOnOk)}
            {add.addModal('Add Production', addOnOk)}
            {/* {reverse.addModal('Reverse Production', reverseOk)} */}
        </>
    );
}

export default memo(Production);