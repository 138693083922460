
import React, { useState, useEffect, memo, useMemo, useRef } from 'react';
import ValuesStore from '../../store/values-store';
import { useSearchParams, Link, useLocation, useNavigate } from 'react-router-dom';
import utils from '../../dependencies/custom/react-utilities';
import { Space, Button, message } from 'antd';
// import { Card, Input, Avatar, Dropdown, Button, Affix, Space, Modal, Empty, Badge, Menu } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import qs from 'qs';
import useTable from '../../hooks/table';
import useAdd from '../../hooks/add';
import useEdit from '../../hooks/edit';
import useDelete from '../../hooks/delete';
import Settings from '../../dependencies/custom/settings';


const Admin = (props) => {
    const valuesStore = ValuesStore();
    const add = useAdd('tables_metadata', 'table_name');
    const edit = useEdit('tables_metadata', 'table_name');//make this hook be aware of where to get tbl metadata 
    const del = useDelete();
    const { filters, filterTypes } = utils.generateTableFilters();
    const keyOverrides = { categoryAlias: 'category' };
    const [modalTitle, setModalTitle] = useState('Add New User');
    //and key value that points to the table names from zustand store.    
    const table = useTable(
        {
            pagination: {
                current: 1,
                pageSize: 10,
                position: ['bottomRight'],
                hideOnSinglePage: true
            },
            filters: { ...filters },
            filterTypes: { ...filterTypes }
        },
        `${Settings.backend}/get_data`,
        'post',
        'result',
        'totalCount',
        'id',
        { /*alias: 'LIKE', acadyr: 'LIKE', semester: 'IN', end_date: 'IN', is_active: 'IN' */ },
        { table: 'admin', fields: ['*'] });


    const columns = ([
        {
            title: 'Name',
            dataIndex: 'name',
            ...table.getColumnSearchProps('name'),
        },
        {
            title: 'Username',
            dataIndex: 'username',
            ...table.getColumnSearchProps('username'),
        },
        {
            title: 'StaffID',
            dataIndex: 'staff_id',
            ...table.getColumnSearchProps('staff_id'),
        },
        {
            title: 'Date Created',
            dataIndex: 'date_inserted',
            ...table.getColumnSearchProps('date_inserted'),
            render: (v, record) => {
                return utils.formatDate(v);
            }
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => {
                return <Space size="middle">
                    <Button className='btn-success border-0' onClick={e => editRecord(record, 'admin')}><i className='fas fa-edit text-successx' /></Button>
                    {del.confirm(
                        `${Settings.backend}/delete`,
                        record,
                        'Are you sure to delete this item',
                        { tableName: 'admin', where: 'id', whereType: 'closed' },
                        <Button className='btn-danger border-0'>  <i className='fas fa-trash text-dangerx' /></Button>
                    )}
                </Space>
            },
        },
    ]);


    function editRecord(record, tableName) {
        const storeKey = 'editableRecord';
        valuesStore.setValue(storeKey, record);
        edit.setTblName(tableName);
        edit.setData(record);
        edit.setRecordKey(storeKey);
        edit.setShowModal(true);
        edit.setSaveCompleted(false);
    }

    function addRecord(tableName = 'admin') {
        add.setTblName(tableName);
        add.setShowModal(true);
        add.setSaveCompleted(false);
    }

    async function editOnOk() {
        let res = await edit.save(undefined, `${Settings.backend}/edit`, 'admin', { tbl: 'admin', where: 'id', whereType: 'closed' })
    }

    async function addOnOk() {
        let res = await add.save(`${Settings.backend}/add`, { tbl: 'admin' })
    }



    useMemo(() => {
        table.setColumns(columns);
        console.log('looping')
        table.fetchData();
    }, [add.saveCompleted, del.saveCompleted, edit.saveCompleted, table.extraFetchParams]);


    return (
        <>
            {/* {console.log('render render')} */}
            <div className='container' /*style={{ marginTop: '4rem' }}*/>
                <div className='row'>
                    <div className='col-md-12 mb-2'>
                        <Space>
                            <Button className='btn-danger border-0' onClick={e => addRecord()}><i className='fas fa-user-plus me-2' /> Add New Item</Button>
                        </Space>
                    </div>
                    <div className='col-md-12'>
                        {table.table}
                    </div>
                </div>
            </div>
            {add.addModal(modalTitle, addOnOk)}
            {edit.editModal('Edit Settings', editOnOk)}
        </>
    );
}

export default memo(Admin);