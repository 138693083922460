import React, { useEffect, useState } from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
// import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import './css/mdb.min.css';
import './font/fontawesome/css/all.min.css'
import './css/custom/animations.css';
import './css/custom/tweeks.css';
import './css/bootstrap5.min.css';
import "./css/styles/main.css";
import "./css/styles/responsive.css";


import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import usePhoneBaseMenu from './js/components/phoneBaseMenu';
import useWindowDimensions from './js/components/screensize';

import SettingsStore from './js/store/settings-store';
import ValuesStore from './js/store/values-store';
import utils from './js/dependencies/custom/react-utilities'
import IndexedDB from './js/dependencies/custom/indexeddb'
import Settings from './js/dependencies/custom/settings';

//admin paths
import AdminHome from './js/pages/admin/home';
import AdminSetting from './js/pages/admin/settings';
import AdminFiles from './js/pages/admin/admin_files';
import AdminPerms from './js/pages/admin/admin_perms';
import AdminRoles from './js/pages/admin/admin_roles';
import Admin from './js/pages/admin/admin';
import AdminRoleFilesLink from './js/pages/admin/admin_role_files_link';
import AdminRolePerm from './js/pages/admin/admin_role_perm';
import AdminRoleLink from './js/pages/admin/admin_role_link';
import Login from './js/pages/admin/login';
import NotFound from './js/pages/admin/404';
import ChangePassword from './js/pages/admin/change_password';

import Dashboard from './js/pages/admin/dashboard';
import InitRecoverPassword from './js/pages/admin/init_recover_password';
import CompleterecoverPassword from './js/pages/admin/complete_recover_password';
import FlowTypes from './js/pages/admin/flow_types';
import Production from './js/pages/admin/production';
import Sales from './js/pages/admin/sales';
import Delivery from './js/pages/admin/delivery';
import Damages from './js/pages/admin/damages';
import Cost from './js/pages/admin/cost';
import Income from './js/pages/admin/income';
import Transactions from './js/pages/admin/transactions';
import BalancePayment from './js/pages/admin/balance_payment';
import Customers from './js/pages/admin/customers';
import Products from './js/pages/admin/products';
import Reversals from './js/pages/admin/reversals';
import Suppliers from './js/pages/admin/suppliers';
import Inventory from './js/pages/admin/inventory';
import Sandtrip from './js/pages/admin/sandtrip';
import Users from './js/pages/admin/users';
import Trucks from './js/pages/admin/trucks';


function App() {
  const settingsStore = SettingsStore();
  const valuesStore = ValuesStore();
  const navigate = useNavigate();
  const location = useLocation();
  const { vpWidth } = useWindowDimensions();
  const phoneBaseMenu = usePhoneBaseMenu();
  // const [homePageCardSize, setHomePageCardSize] = useState('3');  
  // const [bannerType, setBannerType] = useState('jumbotron');

  const fetchItems = [
    {
      method: 'post',
      sql: 'SELECT * FROM settings WHERE is_public = 1',
      url: `${Settings.backend}/bootstrap_others`,
      storeName: 'configSettings',
    }
  ];

  const newLocation = useLocation();
  const urlFileName = utils.getUrlFileName();

  function hasRoute() {
    const timer = setInterval(() => {
      const routesAvailable = valuesStore.getValue('permitted_routes');
      if (routesAvailable.length > 0) {
        clearInterval(timer);
        const routes = valuesStore.getArrayObjectsValue('permitted_routes', 'path', urlFileName);
        if (Object.keys(routes).length <= 0) {
          navigate(-1);//go back to the previous page if the requested routes is not found in what the use can access
        }
      }
    }, 1000);
  }

  useEffect(() => {    
    if (!(['/login','/init_psd_recovery','/complete_recover_password'].includes(location.pathname)) ) {
      utils.bootstrap(valuesStore, settingsStore);
    }
    // utils.bootstrapOthers(valuesStore, fetchItems);
    // hasRoute();

    return () => {
      //clean up here
    };
  }, [newLocation, valuesStore['loggedIn']]);

  return (
    <>
      <Routes>
        <Route path='/login' element={<Login />} />
        <Route path='/' element={<Login />} />
        <Route path='/init_psd_recovery' element={<InitRecoverPassword  />} />
        <Route path='/complete_recover_password' element={<CompleterecoverPassword  />} />        
        <Route path='/admin' element={<AdminHome />}>                    
          <Route path='settings' element={<AdminSetting />} />
          <Route path='admin' element={<Admin />} />
          <Route path='admin_files' element={<AdminFiles />} />
          <Route path='admin_roles' element={<AdminRoles />} />
          <Route path='admin_perms' element={<AdminPerms />} />
          <Route path='admin_role_files_link' element={<AdminRoleFilesLink />} />
          <Route path='admin_role_link' element={<AdminRoleLink />} />
          <Route path='admin_role_perm' element={<AdminRolePerm />} />
          <Route path='change_password' element={<ChangePassword />} />          
          <Route path='dashboard' element={<Dashboard />} />
          <Route path='flow_types' element={<FlowTypes />} />
          <Route path='production' element={<Production />} />
          <Route path='sales' element={<Sales />} />
          <Route path='delivery' element={<Delivery />} />
          <Route path='damages' element={<Damages />} />
          <Route path='cost' element={<Cost />} />
          <Route path='income' element={<Income />} />
          <Route path='transactions' element={<Transactions />} />
          <Route path='balance_payment' element={<BalancePayment />} />
          <Route path='customers' element={<Customers />} />
          <Route path='products' element={<Products />} />
          <Route path='reversals' element={<Reversals />} />
          <Route path='suppliers' element={<Suppliers />} />
          <Route path='inventory' element={<Inventory />} />
          <Route path='sandtrip' element={<Sandtrip />} />
          <Route path='users' element={<Users />} />
          <Route path='trucks' element={<Trucks />} />
          <Route path="*" element={<NotFound/>}/>
        </Route>
        <Route path="*" element={<NotFound/>}/>

      </Routes>

      {/* {vpWidth < 700 && (['/vpayment', '/', '/terms_of_service', '/privacy_policy', '/sell'].includes(window.location.pathname) ? '' : phoneBaseMenu.baseMenu('phoneBaseMenu'))} */}
    </>

  )
}

//disable auto zoom in on iphone and ipad. iphone and ipad automatically zoom in when text size is less than 16px
if (utils.checkIsIOS()) {
  utils.addMaximumScaleToMetaViewport();
}

export default App;
